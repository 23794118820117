import React, { useContext } from 'react';
import { WindowLocation } from '@reach/router';
import { GlobalStyle } from '@shipae/components-sandbox';

import { DEFAULT_FONT_SIZE } from 'src/views/theme';
import { I18nProvider } from 'src/views/misc/localization';
import RTLProvider from 'src/views/misc/RTLProvider';
import {
  useMouseDetector,
} from '@shipae/components-sandbox/component/hooks/use-mouse-detector';
import { Helmet } from 'react-helmet';
import { ProjectStyles } from './layout.styles';
import Header from '../header';
import Footer from '../footer';
import { PageContext } from '../../templates/context';
import TopLogo from './top-logo';
import useAnalyticsListener from './use-analytics-listener';
import NoJavascript from '../no-javascript';

export interface IProps {
  location?: WindowLocation;
}

const Shared: React.FC<IProps> = ({ children, location }) => {
  useAnalyticsListener(location);

  const {
    additionalPageSettings,
  } = useContext(PageContext);

  useMouseDetector();
  return (
    <RTLProvider>
      <I18nProvider>
        <Helmet>
          <style>
            {/* eslint-disable max-len */}
            {`
            @font-face {
              font-family: 'Futura PT';
              src: url('/fonts/FuturaPT-Light.woff2'), url('/fonts/FuturaPT-Light.woff');
              font-weight: 100;
              font-display: swap;
            }
            @font-face {
              font-family: 'Futura PT';
              src: url('/fonts/FuturaPT-Book.woff2'), url('/fonts/FuturaPT-Book.woff');
              font-weight: 200;
              font-display: swap;
            }
            @font-face {
              font-family: 'Futura PT';
              src: url('/fonts/FuturaPT-Medium.woff2'), url('/fonts/FuturaPT-Medium.woff');
              font-weight: 300;
              font-display: swap;
            }
            @font-face {
              font-family: 'Futura PT';
              src: url('/fonts/FuturaPT-Demi.woff2'), url('/fonts/FuturaPT-Demi.woff');
              font-weight: 400;
              font-display: swap;
            }
          `}
            {/* eslint-enable max-len */}
          </style>
          <link
            href="https://www.googletagmanager.com"
            rel="preconnect"
            crossOrigin="anonymous"
          />
          <link
            rel="dns-prefetch"
            href="https://www.googletagmanager.com"
          />
          {!additionalPageSettings?.disableLiveChat && (
            <script
              src="https://uae.fw-cdn.com/40156381/94720.js"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error – Reason: required by new chat for integration
              chat="true"
              widgetId="c8e84bd0-9a14-44a3-af65-0d577a364198"
            />
          )}
        </Helmet>
        <GlobalStyle
          fontSizes={{
            defaultFontSize: DEFAULT_FONT_SIZE,
            xs: 14,
            sm: 16,
            md: 18,
            lg: 20,
            xl: 20,
          }}
        />
        <ProjectStyles />
        {additionalPageSettings?.topLogo && (
          <TopLogo image={additionalPageSettings.topLogo} />
        )}
        {!additionalPageSettings?.disableHeader && <Header />}
        {children}
        {!additionalPageSettings?.disableFooter && <Footer />}
        <NoJavascript />
      </I18nProvider>
    </RTLProvider>
  );
};

export default Shared;
