import React, { useMemo } from 'react';
import { Locales } from 'src/application/enums/locales';
import { useTranslation } from 'src/views/misc/localization';
import {
  useFooterData,
  Footer as CmsContent,
} from 'src/infrastructure/hooks/use-footer-data';
import {
  onTrackShipmentClick,
} from 'src/application/core/header/actions';
import { useDispatch } from 'react-redux';
import PureFooter from '@shipae/components-sandbox/component/footer';
import {
  IFooterContent,
} from '@shipae/components-sandbox/component/footer/types';
import { ROUTES } from 'src/views/constants/ROUTES';
import SmartLink from '../smart-link';
import { useLegalLinkinKSA } from './use-legal-link-in-ksa';
import { NoTopPanelWrapper } from './styles';

const prepareContent = (
  cmsContent: CmsContent,
  locale: string,
): IFooterContent => ({
  ...cmsContent,
  homeUrl: ROUTES[locale].HOME,
  logo: {
    url: cmsContent.logo.localFile.publicURL,
    alternativeText: cmsContent.logo.alternativeText || '',
  },
  topPanel: !cmsContent.topPanel ? undefined : {
    ...cmsContent.topPanel,
    leftAction: {
      ...cmsContent.topPanel.leftAction,
      icon: {
        url: cmsContent.topPanel.leftAction.icon.localFile.publicURL,
        // eslint-disable-next-line max-len
        alternativeText: cmsContent.topPanel.leftAction.icon.alternativeText || '',
      },
    },
    rightAction: {
      ...cmsContent.topPanel.rightAction,
      icon: {
        url: cmsContent.topPanel.rightAction.icon.localFile.publicURL,
        // eslint-disable-next-line max-len
        alternativeText: cmsContent.topPanel.rightAction.icon.alternativeText || '',
      },
    },
  },
  socialMediaLinks: cmsContent.socialMediaLinks.map((link) => ({
    ...link,
    icon: {
      url: link.icon.localFile.publicURL,
      alternativeText: link.icon.alternativeText || '',
    },
  })),
  bottomIcons: cmsContent.bottomIcons.map((link) => ({
    ...link,
    icon: {
      url: link.icon.localFile.publicURL,
      alternativeText: link.icon.alternativeText || '',
    },
  })),
  copyright: cmsContent.copyright
    .replace('YYYY', new Date().getFullYear().toString()),
});

const Footer: React.FC = () => {
  const dispatch = useDispatch();
  const footer = useFooterData();

  const { t, locale } = useTranslation();

  const localizedContent = footer[locale as Locales];

  if (!localizedContent) return null;

  const content = useMemo(
    () => prepareContent(localizedContent, locale),
    [localizedContent, locale],
  );

  const sitemapUrl = ROUTES[locale].SITEMAP;

  const onOpenChatClick = () => {
    // The new chat is integrated via an embedded script and doesn't support
    // opening/closing via code.
    // The Top footer panel (where was action to open the chat) is hidden
    // via CSS, but CMS content loading remains for future use.
  };

  useLegalLinkinKSA(); // Only works in Egypt

  return (
    <div data-ga="Footer">
      <NoTopPanelWrapper>
        <PureFooter
          isLoading={false} // since content is pre-fetched
          content={content}
          linkComponent={SmartLink}
          widgetActions={{
            onOpenChatClick,
            onTrackShipmentClick: () => dispatch(onTrackShipmentClick(locale)),
          }}
          sitemapUrl={sitemapUrl}
          sitemapLabel={t('footerSitemapLinkLabel')}
        />
      </NoTopPanelWrapper>
    </div>
  );
};

export default Footer;
