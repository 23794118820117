import styled from 'styled-components';

export const NoTopPanelWrapper = styled.div`
    & footer > section:first-child {
        display: none;
    }
    & footer > section[data-test='footer-top-panel']{
        display: none;
    }
`;
